import React from 'react'
import { connect } from 'react-redux'
import Testimonials from '../testimonials'

function TestimonialsEn({ translations, lang }) {
    return (
        <Testimonials translations={translations} lang={lang} />
    )
}

const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(TestimonialsEn)
